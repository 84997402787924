import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'enabavka-web';

  ngOnInit()
  {
    console.log('Version 3.0');
    navigator.serviceWorker.register('service-worker.js').then(function (registration) {
    });
  }
}


