import { Component, OnInit } from '@angular/core';
import {StartComponent} from '../start/start.component';
import {ActivatedRoute, Router} from '@angular/router';
import {MainComponent} from '../main/main.component';
import {TranslateService} from '@ngx-translate/core';
import {DataService} from '../data.service';
import {ApiServiceService} from '../api-service.service';
import {NewsOrNotice} from '../model';

@Component({
  selector: 'app-news-notice',
  templateUrl: './news-notice.component.html',
  styleUrls: ['./news-notice.component.css']
})
export class NewsNoticeComponent implements OnInit {
  id: number;
  type: number;
  obj: NewsOrNotice;
  loading = {main: true, other: true};
  objects: NewsOrNotice[];

  constructor(private router: Router, public start: StartComponent, private route: ActivatedRoute, private main: MainComponent, private translate: TranslateService, private dataService: DataService, private apiService: ApiServiceService) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.type = +params['type'];
      this.id = +params['id'];


      this.apiService.getNewsOrNotice(this.id, this.type).subscribe(
        (data: NewsOrNotice) => {
          this.obj = data;
          this.loading.main = false;
        },
        err => {
          localStorage.setItem('autoLogin', this.router.url);
          this.main.autoLogin(err.status);
        }
      );

      if (this.type === 1) {
        this.main.text = 'news';
        this.apiService.getNews(0).subscribe(
        (data: NewsOrNotice[]) => {
          const objects = data;
         if (this.obj) {
          const index = objects.findIndex((x) => x.id == this.obj.id);
          if (index != -1) {
            objects.splice(index, 1);
          }
         }
          this.objects = objects;
          this.loading.other = false;
        },
          err => {
            localStorage.setItem('autoLogin', this.router.url);
          this.main.autoLogin(err.status);
          }
      );
      } else if (this.type === 2) {
        this.main.text = 'notifications';
        this.apiService.getNotices(0).subscribe(
          (data: NewsOrNotice[]) => {
            const objects = data;
            if (this.obj) {
              const index = objects.findIndex((x) => x.id == this.obj.id);
              if (index != -1) {
                objects.splice(index, 1);
              }
            }
            this.objects = objects;
            this.loading.other = false;
          },
          err => {
            localStorage.setItem('autoLogin', this.router.url);
            this.main.autoLogin(err.status);
          }
        );
      } else if (this.type == 3) {
        this.main.text = 'suppliers';
        this.apiService.getSuppliers(0).subscribe(
          (data: NewsOrNotice[]) => {
            const objects = data;
            if (this.obj) {
              const index = objects.findIndex((x) => x.id == this.obj.id);
              if (index != -1) {
                objects.splice(index, 1);
              }
            }
            this.objects = objects;
            this.loading.other = false;
          },
          err => {
            localStorage.setItem('autoLogin', this.router.url);
            this.main.autoLogin(err.status);
          }
        );
      }

    });
  }

}
