import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Cart} from "../model";
import {ApiServiceService} from "../api-service.service";
import {MainComponent} from "../main/main.component";
import {MatDialog, MatSnackBar} from "@angular/material";
import {TranslateService} from "@ngx-translate/core";
import {DialogComponent} from "../dialog/dialog.component";
import {DataService} from "../data.service";

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent implements OnInit {
   order: Cart;
   loading = true;
   loadingEmail = false;

  constructor(private router: Router, public dataService: DataService, public dialog: MatDialog, private translate: TranslateService, private snackBar: MatSnackBar, private main: MainComponent, private route: ActivatedRoute, private apiService: ApiServiceService) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      const id = +params['id'];

      this.apiService.getOrder(id).subscribe(
        (order: Cart) => {
          this.order = order;
          this.loading = false;
        }, err => {
          localStorage.setItem('autoLogin', this.router.url);
          this.main.autoLogin(err.status);
        }
      )
    });
  }

  sendInvoice() {
    this.loadingEmail = true;
    this.apiService.sendInvoice(this.order.id).subscribe(
      data => {
        this.loadingEmail = false;
        this.snackBar.open(this.translate.instant('emailSentTo') + data['mail'], "OK", {duration : 3000});
      }, err => {
        localStorage.setItem('autoLogin', this.router.url);
        this.main.autoLogin(err.status);
      }
    )
  }


  getPluralMeasurumentUnit(id) {

    if (id == 18) {
      return this.translate.instant('itemsp');
    } else if (id == 5) {
      return this.translate.instant('milimitars');
    } else if (id == 2) {
      return this.translate.instant('grams');
    }
  }

  deleteOrder() {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {type: 'removeOrder'}, panelClass : 'warningDialog'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.apiService.deleteOrder(this.order.id).subscribe(
          data => {
            this.dataService.removedOrder.next(this.order.id);
            this.router.navigate(['main/orders']);
          }, err => {
            localStorage.setItem('autoLogin', this.router.url);
            this.main.autoLogin(err.status);
          }
        )
      }
    });
  }

  repeatOrder() {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {type: 'repeatOrder'}, panelClass : 'warningDialog'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result == 'new') {
          this.main.cart.products = [];
          this.order.products.forEach( product => {
            product.id = product.product.id;
            this.main.cart.products.push(product);
          });
          this.main.cart.products = [...this.main.cart.products];
          this.router.navigate(['main/cart']);
        } else if (result == 'existing') {
          this.order.products.forEach( product => {
            product.id = product.product.id;
            this.main.cart.products.push(product);
          });
          this.main.cart.products = [...this.main.cart.products];
          this.router.navigate(['main/cart']);
        }
      }
    });
  }

}
