import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-footer-big',
  templateUrl: './footer-big.component.html',
  styleUrls: ['./footer-big.component.css']
})
export class FooterBigComponent implements OnInit {

  constructor(public router: Router) { }

  ngOnInit() {
  }

  public openFaq() {
    this.router.navigate(['main/faq']);
  }

  public openPrivacyPolicy() {
    this.router.navigate(['main/privacy']);
  }
  public openTermsOfUse() {
    this.router.navigate(['main/terms']);
  }

 }
