import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor() { }

  headerTitle: Subject<string> = new Subject();
  searchText: Subject<string> = new Subject();
  removedOrder: Subject<number> = new Subject();
}
