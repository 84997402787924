import { BrowserModule } from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { MainComponent } from './main/main.component';
import { LoginComponent } from './login/login.component';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFireMessagingModule} from '@angular/fire/messaging';
import {AngularFireModule} from '@angular/fire';
import {environment} from '../environments/environment';
import {AngularFireDatabaseModule} from '@angular/fire/database';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AppRoutingModule} from './app-routing.module';
import {
  MatButtonToggleModule, MatDialog, MatDialogModule,
  MatFormFieldControl,
  MatFormFieldModule,
  MatInputModule, MatProgressSpinnerModule, MatSelectModule, MatSidenavModule,
  MatSnackBar,
  MatSnackBarContainer,
  MatSnackBarModule
} from '@angular/material';
import { ContactPageComponent } from './contact-page/contact-page.component';
import {FormsModule} from '@angular/forms';
import {OverlayModule} from '@angular/cdk/overlay';
import { RegisterComponent } from './register/register.component';
import { StartComponent } from './start/start.component';
import { NewsNoticeComponent } from './news-notice/news-notice.component';
import { SpecificListComponent } from './specific-list/specific-list.component';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import { ProductsComponent } from './products/products.component';
import { FilterProductsComponent } from './filter-products/filter-products.component';
import { CartComponent } from './cart/cart.component';
import { CheckOrderComponent } from './check-order/check-order.component';
import {DragScrollModule} from "ngx-drag-scroll";
import {MessagingService} from "./shared/messaging.service";
import { DialogComponent } from './dialog/dialog.component';
import { OrderHistoryComponent } from './order-history/order-history.component';
import { FilterOrderComponent } from './filter-order/filter-order.component';
import { FooterBigComponent } from './footer-big/footer-big.component';
import { FavoriteProductsComponent } from './favorite-products/favorite-products.component';
import { FavoriteProductsFilterComponent } from './favorite-products-filter/favorite-products-filter.component';
import { OrderComponent } from './order/order.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { ProfileComponent } from './profile/profile.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { EditPasswordComponent } from './edit-password/edit-password.component';
import { ForgottenPassComponent } from './forgotten-pass/forgotten-pass.component';
import { SuppliersComponent } from './suppliers/suppliers.component';
import { NotFoundComponent } from './not-found/not-found.component';
import {ClickOutsideModule} from "ng-click-outside";
import { HelpComponent } from './help/help.component';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { FaqComponent } from './faq/faq.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
registerLocaleData(localeDe);
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

// @ts-ignore
// @ts-ignore
@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    LoginComponent,
    ContactPageComponent,
    RegisterComponent,
    StartComponent,
    NewsNoticeComponent,
    SpecificListComponent,
    ProductsComponent,
    FilterProductsComponent,
    CartComponent,
    CheckOrderComponent,
    DialogComponent,
    OrderHistoryComponent,
    FilterOrderComponent,
    FooterBigComponent,
    FavoriteProductsComponent,
    FavoriteProductsFilterComponent,
    OrderComponent,
    ProductDetailComponent,
    ProfileComponent,
    EditProfileComponent,
    EditPasswordComponent,
    ForgottenPassComponent,
    SuppliersComponent,
    NotFoundComponent,
    HelpComponent,
    FaqComponent,
    PrivacyPolicyComponent,
    TermsOfUseComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatSnackBarModule,
    OverlayModule,
    NgbModule,
    MatInputModule,
    TranslateModule.forRoot({
      defaultLanguage: 'sr',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    MatFormFieldModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    InfiniteScrollModule,
    MatSelectModule,
    MatButtonToggleModule,
    DragScrollModule,
    MatDialogModule,
    ClickOutsideModule
  ],
  providers: [MatDialog, FilterOrderComponent,  MessagingService, MainComponent, StartComponent, ProductsComponent, MatSnackBar, { provide: LOCALE_ID, useValue: 'de-DE'}],
  bootstrap: [AppComponent],
  entryComponents: [DialogComponent]
})
export class AppModule { }
