import {Component, OnInit} from '@angular/core';
import {Cart, OrderFilter} from "../model";
import {ApiServiceService} from "../api-service.service";
import {Router} from "@angular/router";
import {FilterOrderComponent} from "../filter-order/filter-order.component";
import {MainComponent} from "../main/main.component";
import {DataService} from "../data.service";

@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.css']
})
export class OrderHistoryComponent implements OnInit {
  orderFilter: OrderFilter = {dateFrom: '-1', dateTo: '-1', month: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11], year: []};
  page = 0;
  orders: Cart[];
  loading = true;
  total = 0;
  allMonths = false;
  allYears = false;
  months = [];
  years = [];
  allYearsArr = [];

  constructor(private dataService: DataService, private main: MainComponent, public filter: FilterOrderComponent, private router: Router, private apiService: ApiServiceService) {
  }

  ngOnInit() {
    this.dataService.removedOrder.subscribe(
      (id) => {
        if (id) {
          const index = this.orders.findIndex(x => x.id == id);

          if (index != -1) {
            this.orders.splice(index,1);
          }
        }
      }
    );
    this.getYears();
    this.months = this.filter.months.slice(0, 4);
    this.years = this.allYearsArr.slice(0, 4);

    if (localStorage.getItem('orderFilter')) {
      this.orderFilter = JSON.parse(localStorage.getItem('orderFilter'));
      this.filterOrders();
    } else {
      this.orderFilter = {dateFrom: '-1', dateTo: '-1', month: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11], year: []};
      this.getYears();
      this.filterOrders();
    }
  }

  getYears() {
    this.allYearsArr = [];
    this.orderFilter.year = [];

    const year = new Date().getFullYear();

    for (let i = 0; i <= 10; i++) {
      const newYear = year - i;
      this.orderFilter.year.push(newYear);
      this.allYearsArr.push(newYear);
    }
  }

  filterOrders() {
    this.apiService.getOrders(this.orderFilter, this.page).subscribe(
      data => {
        this.orders = data['orders'];
        this.total = data['total'];
        this.loading = false;
      }
    );
  }

  onScroll() {
    this.page++;
    this.apiService.getOrders(this.orderFilter, this.page).subscribe(
      data => {
        const orders = data['orders'];
        this.orders = [...this.orders, ...orders];
        this.loading = false;
      }
    );
  }

  onNavClick(url, id = -1) {
    if (id == -1) {
      this.router.navigate([url]);
    } else {
      this.router.navigate([url, id]);
      this.main.text = 'order';

    }

  }

  checkIfChecked(value, type) {
    if (type == 'm') {
      const index = this.orderFilter.month.findIndex(x => x == value);
      if (index != -1 ) {
        return true;
      } else {
        return false;
      }
    } else {
      const index = this.orderFilter.year.findIndex(x => x == value);
      if (index != -1 ) {
        return true;
      } else {
        return false;
      }
    }
  }

  toggle(value, type) {
    if (type == 'm') {
      const index = this.orderFilter.month.findIndex(x => x == value);
      if (index != -1 ) {
        this.orderFilter.month.splice(index, 1);
      } else {
        this.orderFilter.month.push(value);
      }

      this.orderFilter.month = [...this.orderFilter.month];

    } else {
      const index = this.orderFilter.year.findIndex(x => x == value);
      if (index != -1 ) {
        this.orderFilter.year.splice(index, 1);
      } else {
        this.orderFilter.year.push(value);
      }

      this.orderFilter.year = [...this.orderFilter.year];
    }
    this.loading = true;
    this.page = 0;
    localStorage.setItem('orderFilter', JSON.stringify(this.orderFilter));
    this.filterOrders();
  }

  showAll(type) {
    if (type == 'm') {
      this.allMonths = true;
      this.months = [...this.filter.months];
    } else {
      this.allYears = true;
      this.years = [...this.allYearsArr];
    }
  }

}
