import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Customer} from "../model";
import {ApiServiceService} from "../api-service.service";
import {MatSnackBar} from "@angular/material";
import {TranslateService} from "@ngx-translate/core";
import {MainComponent} from "../main/main.component";
import {Router} from "@angular/router";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
customer: Customer;
mode = 'info';
actualPassword = '';
newPassword = '';
repeatPassword = '';
requestedEditChange = false;
requestedPasswordChange = false;
code = '';
  @ViewChild("codeForm", {static: false}) codeForm: ElementRef;

  constructor(private router: Router, private main: MainComponent, private translate: TranslateService, private apiService: ApiServiceService, private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.customer = JSON.parse(localStorage.getItem('customer'));
  }

  changeMode(mode) {
    this.mode = mode;
  }

  updateProfile() {
    this.requestedEditChange = true;
    this.apiService.requestUpdate().subscribe(
      data => {
        setTimeout(() => { //wait 100ms to load this input
          this.codeForm.nativeElement.focus();
        }, 100);
      }, err => {
        localStorage.setItem('autoLogin', this.router.url);
        this.main.autoLogin(err.status);
      }
    )
  }

  updateProfileWithCode() {
    this.customer.code = this.code;
    this.apiService.updateProfile(this.customer).subscribe(
      (customer: Customer) => {
        this.customer = customer;
        localStorage.setItem('customer', JSON.stringify(this.customer));
        this.mode = 'info';
        this.requestedEditChange = false;
        }, err => {
        if (err.status == 404) {
          this.snackBar.open(this.translate.instant('wrongCode'), "OK", {duration : 3000});
        } else {
          localStorage.setItem('autoLogin', this.router.url);
          this.main.autoLogin(err.status);
        }
      }
    )
  }

  changePassword() {
    if (this.newPassword == '' || this.actualPassword == '' || this.repeatPassword == ''){
      this.snackBar.open(this.translate.instant('pleaseCompleteAllFields'), "OK", {duration: 3000});
    } else if (this.newPassword != this.repeatPassword) {
      this.snackBar.open(this.translate.instant('passwordsAreNotSame'), "OK", {duration: 3000});
    } else if (this.actualPassword == this.newPassword) {
      this.snackBar.open(this.translate.instant('newPasswordCantBeOld'), "OK", {duration: 3000});
    } else if (this.newPassword.length < 6) {
      this.snackBar.open(this.translate.instant('passwordMustBeAtLeast6'), "OK", {duration: 3000});
    } else {
      this.apiService.requestUpdate().subscribe(
        data => {
          this.requestedPasswordChange = true;
          setTimeout(() => { //wait 100ms to load this input
            this.codeForm.nativeElement.focus();
          }, 100);
        }, err => {
          localStorage.setItem('autoLogin', this.router.url);
          this.main.autoLogin(err.status);
        }
      )
      setTimeout(() => { //wait 100ms to load this input
        this.codeForm.nativeElement.focus();
      }, 100);
    }
  }

  changePasswordWithCode(){
    this.apiService.changePassword(this.code, this.newPassword, this.actualPassword).subscribe(
      data => {
        this.mode = 'info';
        this.requestedPasswordChange = false;
      }, err => {
        if (err.status == 405) {
          this.snackBar.open(this.translate.instant('newPasswordCantBeOld'), "OK", {duration: 3000});
        } else if (err.status == 406) {
          this.snackBar.open(this.translate.instant('oldPasswordNotCorrect'), "OK", {duration: 3000});
        } else {
          localStorage.setItem('autoLogin', this.router.url);
          this.main.autoLogin(err.status);
        }
      }
    )
  }

}
