import {AfterViewChecked, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Cart, Customer} from '../model';
import {MatDialog, MatSnackBar} from '@angular/material';
import {ApiServiceService} from '../api-service.service';
import {TranslateService} from '@ngx-translate/core';
import {DataService} from '../data.service';
import {Location} from '@angular/common';
import {MessagingService} from "../shared/messaging.service";
import {DialogComponent} from "../dialog/dialog.component";

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit, AfterViewChecked {

  public backRoute = '';
  public iconType = 0; // 0 - home, 1 - cart, 2 - fullCart
  public text = null;
  public cart: Cart;
  showIcon = true;
  showNav = false;
  customer: Customer;

  constructor(public dialog: MatDialog, private messagingService: MessagingService, private location: Location, public router: Router, private snackBar: MatSnackBar, private apiService: ApiServiceService, private translate: TranslateService, private dataService: DataService) { }

  ngOnInit() {
    if (!localStorage.getItem('customer')) {
      localStorage.setItem('url', this.location.path());
      this.router.navigate(['login']);
    }
    this.cart = {products : []};
    if (localStorage.getItem('cart')){
      this.cart = JSON.parse(localStorage.getItem('cart'));
    }

    if (this.router.url === '/main/products') {
      this.text = 'products';
    } else if (this.router.url === '/main/filter' || this.router.url === '/main/favorite/filter') {
      this.text = 'filter';
    } else if (this.router.url === '/main/cart') {
      this.text = 'cart';
    } else if (this.router.url === '/main/cart/confirm') {
      this.text = 'checkOrder';
    } else if (this.router.url === '/main/orders' || this.router.url === '/main/orders/filter') {
      this.text = 'ordersArchive';
    } else if (this.router.url === '/main/favorite') {
      this.text = 'favorites';
    } else if (this.router.url.startsWith('/main/order/')) {
      this.text = 'order';
    } else if (this.router.url.startsWith('/main/product/')) {
      this.text = 'product';
    } else if (this.router.url == '/main/profile') {
      this.text = 'profile';
    }  else if (this.router.url == '/main/profile/edit') {
      this.text = 'editProfile';
    } else if (this.router.url == '/main/password') {
      this.text = 'changePassword';
    } else if (this.router.url == '/main/suppliers') {
      this.text = 'suppliers';
    }

    this.dataService.headerTitle.subscribe(
      title => {
        if (title != null) {
          this.text = title;
        }
      }
    );
    this.customer = JSON.parse(localStorage.getItem('customer'));
  }

  ngAfterViewChecked() {
    if (this.router.url === '/main/products') {
      this.text = 'products';
    } else if (this.router.url === '/main/filter' || this.router.url === '/main/favorite/filter') {
      this.text = 'filter';
    } else if (this.router.url === '/main/cart') {
      this.text = 'cart';
    } else if (this.router.url === '/main/cart/confirm') {
      this.text = 'checkOrder';
    } else if (this.router.url === '/main/orders' || this.router.url === '/main/orders/filter') {
      this.text = 'ordersArchive';
    } else if (this.router.url === '/main/favorite') {
      this.text = 'favorites';
    } else if (this.router.url.startsWith('/main/order/')) {
      this.text = 'order';
    } else if (this.router.url.startsWith('/main/product/')) {
      this.text = 'product';
    } else if (this.router.url == '/main/profile') {
      this.text = 'profile';
    }  else if (this.router.url == '/main/profile/edit') {
      this.text = 'editProfile';
    } else if (this.router.url == '/main/password') {
      this.text = 'changePassword';
    } else if (this.router.url == '/main/suppliers') {
      this.text = 'suppliers';
    }
  }

  openCart() {
    this.text = 'cart';
    this.router.navigate(['main/cart']);
  }

  goBack() {
    this.location.back();
  }

  onNavClick(address: string, id = -1, type = -1) {
    if (id === -1 && type === -1) {
      this.router.navigate([address]);
    }  else if (id === -1) {
      this.router.navigate([address, type]);
    } else {
      this.router.navigate([address, id, type]);
    }

  }

  logOut() {
    localStorage.removeItem('customer');
    localStorage.removeItem('cart');
    localStorage.removeItem('searchText')
    this.router.navigate(['login']);
  }

  public  autoLogin(status) {
    if (status == 401) {
      if (this.customer.rememberMe === true && this.customer.rememberToken !== null) {
        this.apiService.loginWithToken(this.customer).subscribe(
          data => {
            localStorage.setItem('JWT', data['jwt']);
            if (localStorage.getItem('autoLogin')) {
              location.reload();
              localStorage.removeItem('autoLogin');
            }
          },
          error => {
            this.snackBar.open(this.translate.instant('yourSessionTimedOut'), "OK");
            localStorage.removeItem('customer');
            this.router.navigate(['login']);
          }
        );
      } else {
        this.snackBar.open(this.translate.instant('yourSessionTimedOut'), "OK");
        localStorage.removeItem('customer');
        this.router.navigate(['login']);
      }
    } else {
      this.snackBar.open(this.translate.instant('thereWasAnError'), "OK");

    }
  }

  openFavorites() {
    this.text = 'favorites';
    this.router.navigate(['main/favorite']);

  }

  resetAndOpenProducts() {
    const filter = {active: 1, action: 0, favorite: 0, stock: -1, manufacturers: [0], suppliers: [0], name: ''};
    localStorage.setItem('productFilter', JSON.stringify(filter));
    localStorage.setItem('productsTitle', 'products');
    this.router.navigate(['main/products']);
  }

  openActions() {

    const filter = {active: 1, action: 1, stock: -1, manufacturers: [0], suppliers: [0], name: ''};
    localStorage.setItem('productFilter', JSON.stringify(filter));
    localStorage.setItem('productsTitle', 'actions');
    this.router.navigate(['main/products']);

  }

  findIcon() {
    if (!this.router.url.startsWith('/main/specifics')
      && !this.router.url.startsWith('/main/filter')
      && !this.router.url.startsWith('/main/cart')&&
      !this.router.url.startsWith('/main/confirm')
      && this.router.url != '/main/profile') {
      if (this.cart.products.length == 0) {
        return 'cartEmpty';
      } else {
        return 'cart';
      }
    } else if (this.router.url.startsWith('/main/specifics')
      || this.router.url.startsWith('/main/cart') ||
      this.router.url.startsWith('/main/cart/confirm')
      ||  this.router.url.startsWith('/main/orders/filter')) {
      return 'home';
    } else if (this.router.url === '/main/filter') {
      return 'close';
    } else if (this.router.url === '/main/profile') {
      return 'edit';
    }
  }

  editProfile() {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {type: 'chooseEdit'}, panelClass : 'warningDialog'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result == 'edit') {
          this.router.navigate(['main/profile/edit'])
        } else if (result == 'password') {
          this.router.navigate(['main/password'])
        }
      }
    });
  }

}
