import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MainComponent} from "../main/main.component";
import {ApiServiceService} from "../api-service.service";
import {Cart} from "../model";
import {Router} from "@angular/router";
import {MatDialog, MatSnackBar} from '@angular/material';
import {DialogComponent} from "../dialog/dialog.component";
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {
  loading = true;
  unfulfilledDelivery = [];
  expandComments = false;


  constructor(private snackBar: MatSnackBar,public translate: TranslateService, public dialog: MatDialog, private router: Router, public main: MainComponent, private apiService: ApiServiceService) {
  }


  ngOnInit() {
    this.getCart();
  }

  openProductDetails(id) {
    this.router.navigate(['main/product', id]);
  }

  clearCart() {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {type: 'removeFromCart'}, panelClass: 'warningDialog'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.main.cart = {products: []};
        this.unfulfilledDelivery = [];
        localStorage.removeItem('cart');
      }
    });
  }

  getCart() {
    this.loading = true;
    this.apiService.addToCart(this.main.cart).subscribe(
      (cart: Cart) => {
        this.main.cart = cart;
        this.main.cart.processed = true;
        this.loading = false;
        this.checkIfAtLeastOneDeliveryCost();
        this.checkIfRabatApplied();
      }, err => {
        localStorage.setItem('autoLogin', this.router.url);
        this.main.autoLogin(err.status);
      }
    )
  }

   numberWithCommas(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  public updateComment(supplier) {
  }

  expandProduct(id) {
    const index = this.main.cart.products.findIndex(x => x.id === id);


    if (this.main.cart.products[index].expanded === true) {
      this.main.cart.products[index].expanded = false;
    } else {
      this.main.cart.products[index].expanded = true;
    }

    this.main.cart.products = [...this.main.cart.products];
  }

  removeFromCart(id) {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {type: 'removeFromCart'}, panelClass: 'warningDialog'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const index = this.main.cart.products.findIndex(x => x.id === id);
        if (index != -1) {
          const diff = this.main.cart.products[index].chosenAmount;
          this.adjustPrice(index, diff, 'remove');
        }
      }
    });
  }

  changedFromField(id) {
    const index = this.main.cart.products.findIndex((x) => x.id == id);

    if (index != -1) {
      if (this.main.cart.products[index].chosenAmount == null || !this.main.cart.products[index].chosenAmount || this.main.cart.products[index].chosenAmount <= 0) {

        if (this.main.cart.products[index].chosenAmount < 0) {
          this.main.cart.products[index].chosenAmount = this.main.cart.products[index].chosenAmount * -1;
        } else {
          this.main.cart.products[index].chosenAmount = 1;
          this.main.cart.products = [...this.main.cart.products];
        }
      }
      this.adjustPrice(index, 0, 'plus');
    }
  }


  addAmount(product) {
    let diff = 0;
    const index = this.main.cart.products.findIndex(x => x.id === product.id);

    if (!this.main.cart.products[index].chosenAmount) {
      this.main.cart.products[index].chosenAmount = 0;
    }
    if(product.lager > this.main.cart.products[index].chosenAmount){

      if (this.main.cart.products[index].chosenPacket) {
      diff = this.main.cart.products[index].product.packetNumber;
      this.main.cart.products[index].chosenAmount = this.main.cart.products[index].chosenAmount + (1 * this.main.cart.products[index].product.packetNumber);
    } else {
      diff = 1;
      this.main.cart.products[index].chosenAmount = this.main.cart.products[index].chosenAmount + 1;
    }
      this.adjustPrice(index, diff, 'plus');
      this.main.cart.products = [...this.main.cart.products];
    }else{
      this.snackBar.open(this.translate.instant('Nema dovoljno na proizvoda na lageru'), "OK", {duration : 3000});

    }

  }

  removeAmount(id) {
    const index = this.main.cart.products.findIndex(x => x.id === id);
    let diff = 0;
    if (!this.main.cart.products[index].chosenAmount) {
      this.main.cart.products[index].chosenAmount = 1;
    }

    if (this.main.cart.products[index].chosenAmount <= 0) {
      this.main.cart.products[index].chosenAmount = 1;
      return;
    }

    if (this.main.cart.products[index].chosenAmount - 1 <= 0) {
      this.main.cart.products[index].chosenAmount = 1;
      return;
    }

    if (this.main.cart.products[index].chosenPacket) {
      diff = this.main.cart.products[index].product.packetNumber;
      this.main.cart.products[index].chosenAmount = this.main.cart.products[index].chosenAmount - (1 * this.main.cart.products[index].product.packetNumber);
      this.adjustPrice(index, diff, 'minus');
    } else {
      diff = 1;
      this.main.cart.products[index].chosenAmount = this.main.cart.products[index].chosenAmount - 1;
      this.adjustPrice(index, diff, 'minus');

    }

    if (this.main.cart.products[index].chosenAmount <= 0) {
      this.main.cart.products[index].chosenAmount = 1;
    }

    this.main.cart.products = [...this.main.cart.products];
  }

  checkIfRabatApplied() {
    this.main.cart.products.forEach((product) => {
      if (product.product.rabat) {
        if (product.product.rabat.newPrice) { //first case, price automatically changed
          product.product.appliedRabat = true;
        } else if (product.product.rabat.percent && !product.product.rabat.conditionAmount) { //second case, no condition
          product.product.appliedRabat = true;
        } else if (product.chosenAmount >= product.product.rabat.conditionAmount) { // condition met
          product.product.appliedRabat = true;
        } else if (product.product.action && product.product.action > 0) { // if rabat condition not met, check if there's action
          product.product.appliedRabat = true;
        } else {
          product.product.appliedRabat = true;
        }
      }
    });
  }

  adjustPrice(index, diff, type) {
    let price = 0;

    const product = this.main.cart.products[index];


    //check if rabat exists

    if (product.product.rabat) {
      if (product.product.rabat.newPrice && !product.product.rabat.conditionAmount) { //first case, price automatically changed
        price = product.product.rabat.newPrice;
        product.product.appliedRabat = true;
      } else if (product.product.rabat.percent && !product.product.rabat.conditionAmount) { //second case, no condition
        product.product.appliedRabat = true;
        price = product.product.fullPrice - (product.product.fullPrice / 100 * product.product.rabat.percent);
      } else if (product.chosenAmount >= product.product.rabat.conditionAmount) { // condition met
        product.product.appliedRabat = true;
        if (product.product.rabat.percent) {
          price = product.product.fullPrice - (product.product.fullPrice / 100 * product.product.rabat.percent);
        } else {
          price = product.product.rabat.newPrice;
        }
      } else if (product.product.action && product.product.action > 0) { // if rabat condition not met, check if there's action
        product.product.appliedRabat = true;
        price = product.product.fullPrice - (product.product.fullPrice / 100 * product.product.action);
      } else {
        product.product.appliedRabat = true;
        price = product.product.fullPrice;
      }

      product.price = +price;

    } else if (product.product.action && product.product.action > 0) { // if rabat doesn't exist, check if there's action
      price = product.product.fullPrice - (product.product.fullPrice / 100 * product.product.action);
      product.price = +price;
    } else {
      price = +product.price;
    }

    const supplierIndex = this.main.cart.suppliers.findIndex(x => x.id == product.product.supplier.id);
    const supplier = this.main.cart.suppliers[supplierIndex];

    // if (type == 'plus') {
    //   supplier.price = supplier.price + price * diff;
    // } else if (type == 'minus' || type == 'remove') {
    //   supplier.price = supplier.price - price * diff;
    // }


    let supplierPrice = 0;

    if (product.product.showPrice == false) {
      product.price = 0;
    }


    this.main.cart.products.forEach(product => { //go through each product for the customer
      if (product.product.supplier.id == this.main.cart.suppliers[supplierIndex].id) {
        if (product.price > 0) {
          supplierPrice = supplierPrice + (+product.price * product.chosenAmount);
        }
      }
    });

    supplier.price = +supplierPrice;

    if (supplier.price >= product.product.supplier.minDelivery) {
      supplier.delivery = 0;
    } else {
      if (supplier.delivery != product.product.supplier.deliveryPrice) {
        supplier.delivery = product.product.supplier.deliveryPrice;
      }
    }


    this.main.cart.suppliers[supplierIndex] = supplier;
    this.main.cart.suppliers = [...this.main.cart.suppliers];

    let deliveryPrice = 0;
    let orderPrice = 0;

    if (type == 'remove') {

      //find other products from this supplier
      let otherProducts = 0;
      this.main.cart.products.forEach(product => {
        if (product.product.supplier.id == this.main.cart.suppliers[supplierIndex].id) {
          otherProducts = otherProducts + 1;
        }
      });


      if (otherProducts == 1) { //if this is the only product, remove the supplier
        this.main.cart.suppliers.splice(supplierIndex, 1);
      }
    }



    //recalculate price
    this.main.cart.suppliers.forEach((supplier) => {
      deliveryPrice = deliveryPrice + +supplier.delivery;
      orderPrice = orderPrice + Number(supplier.priceInt);
    });



    if (type == 'remove') {
      this.main.cart.products.splice(index, 1);
      localStorage.setItem('cart', JSON.stringify(this.main.cart));
      this.main.cart.products = [...this.main.cart.products];
    }


    this.main.cart.price = deliveryPrice + orderPrice;


    this.main.cart.deliveryPrice = deliveryPrice;


    localStorage.setItem('cart', JSON.stringify(this.main.cart));
    this.checkIfAtLeastOneDeliveryCost();
  }

  toggleChosenPacket(id) {
    const index = this.main.cart.products.findIndex(x => x.id === id);
    if (this.main.cart.products[index].chosenPacket === true) {
      this.main.cart.products[index].chosenPacket = false;
    } else {
      this.main.cart.products[index].chosenPacket = true;
    }

    this.main.cart.products = [...this.main.cart.products];
  }

  checkIfDeliveryCosts(id) {
    const index = this.main.cart.suppliers.findIndex(x => x.id == id);
    if (index != -1) {
      if (this.main.cart.suppliers[index].delivery > 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  checkIfAtLeastOneDeliveryCost() {
    const array = [];
    this.main.cart.suppliers.forEach(supplier => {
      if (supplier.delivery > 0) {
        const obj = {id: supplier.id, name: supplier.name, delivery: supplier.delivery, min: supplier.minDelivery};
        array.push(obj);
      }
    });
    this.unfulfilledDelivery = array;
  }

  continue() {
    localStorage.setItem('cart', JSON.stringify(this.main.cart));
    this.router.navigate(['/main/cart/confirm']);
    this.main.text = 'checkOrder';
  }

  findInUnfulfilled(id) {
    const index = this.unfulfilledDelivery.findIndex(x => x.id == id);

    if (index != -1) {
      return this.unfulfilledDelivery[index];
    }
  }

  getPluralMeasurumentUnit(id) {

    if (id == 18) {
      return this.translate.instant('itemsp');
    } else if (id == 5) {
      return this.translate.instant('liters');
    } else if (id == 2) {
      return this.translate.instant('kilograms');
    } else if (id == 21) {
      return this.translate.instant('grams');
    }
  }

  getProductTitle(product) {
    if (product.product.appliedRabat == true) {
      return 'appliedRabat';
    } else {
      return 'action';
    }
  }
}


