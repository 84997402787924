import { Component, OnInit } from '@angular/core';
import {Location} from "@angular/common";
import {MainComponent} from "../main/main.component";
import {Router} from "@angular/router";
import {ContactComment} from "../model";
import {ApiServiceService} from "../api-service.service";
import {MatSnackBar} from "@angular/material";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.css']
})
export class ContactPageComponent implements OnInit {
loggedIn = false;
clickedConfirm = false;
comment: ContactComment = {name: '', email: '', comment: ''};
  constructor(private translate: TranslateService, private apiService: ApiServiceService, private snackBar: MatSnackBar, private router: Router, private location: Location, public main: MainComponent) { }

  ngOnInit() {
    if (localStorage.getItem('customer')) {
      this.loggedIn = true;
    } else {
      this.loggedIn = false;
    }
  }

  onNavClick(address: string, id = -1, type = -1) {
    if (id === -1 && type === -1) {
      this.router.navigate([address]);
    }  else if (id === -1) {
      this.router.navigate([address, type]);
    } else {
      this.router.navigate([address, id, type]);
    }

  }

  logOut() {
    localStorage.removeItem('customer');
    localStorage.removeItem('cart');
    localStorage.removeItem('searchText')
    this.router.navigate(['login']);
  }

  openFavorites() {
    this.main.text = 'favorites';
    this.router.navigate(['main/favorite']);
  }

  openCart() {
    this.main.text = 'cart';
    this.router.navigate(['main/cart']);
  }

  goBack() {
    this.location.back();
  }

  sendComment() {
    this.clickedConfirm = true;
    if (this.comment.name == '' || this.comment.email == '' || this.comment.comment == ''){
      this.snackBar.open(this.translate.instant('pleaseCompleteAllFields'), "OK", {duration: 3000});
    } else {
      this.apiService.addComment(this.comment).subscribe(
        data => {
          this.clickedConfirm = false;
          this.comment =  {name: '', email: '', comment: ''};
          this.snackBar.open(this.translate.instant('commentSent'), "OK", {duration: 3000});
        },
        err => {
          localStorage.setItem('autoLogin', this.router.url);
          this.clickedConfirm = false;
          this.main.autoLogin(err.status);
        }
      )
    }
  }

}
