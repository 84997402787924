import {Component, OnInit} from '@angular/core';
import {ApiServiceService} from "../api-service.service";
import {MatSnackBar} from "@angular/material";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-forgotten-pass',
  templateUrl: './forgotten-pass.component.html',
  styleUrls: ['./forgotten-pass.component.css']
})
export class ForgottenPassComponent implements OnInit {
  verification = false;
  email = '';

  constructor(private translate: TranslateService, private router: Router, private apiService: ApiServiceService, private snackBar: MatSnackBar) {
  }

  ngOnInit() {
  }

  openContact() {
    this.router.navigate(['contact']);
  }

   emailIsValid () {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.email)
  }


  changeEmail() {
    if (!this.emailIsValid()) {
      this.snackBar.open(this.translate.instant("emailNotValid"), "OK", {duration: 3000});
      return;
    }
    this.apiService.forgottenPassword(this.email).subscribe(
      data => {
        this.verification = true;
      }, err => {
        if (err.status == 404) {
          this.snackBar.open(this.translate.instant("emailNotFound"), "OK", {duration: 3000});
        }
      }
    )
  }

  goHome() {
    this.router.navigate(['login']);
  }

}
