import { Component, OnInit } from '@angular/core';
import {MainComponent} from "../main/main.component";
import {Router} from "@angular/router";
import {ApiServiceService} from "../api-service.service";
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material';

@Component({
  selector: 'app-check-order',
  templateUrl: './check-order.component.html',
  styleUrls: ['./check-order.component.css']
})
export class CheckOrderComponent implements OnInit {
  finished = false;
  clickedConfirm = false;

  constructor(public translate: TranslateService, public main: MainComponent, private router: Router, private apiService: ApiServiceService, private snackBar : MatSnackBar) { }

  ngOnInit() {
    if (this.main.cart.products.length == 0) {
      this.router.navigate(['main/products']);
    } else if (!this.main.cart.processed){
      this.router.navigate(['main/cart']);
    }
  }

  getPluralMeasurumentUnit(id) {

    if (id == 18) {
      return this.translate.instant('itemsp');
    } else if (id == 5) {
      return this.translate.instant('milimitars');
    } else if (id == 2) {
      return this.translate.instant('grams');
    }
  }

  createOrder() {
    if (!this.clickedConfirm) {
      this.clickedConfirm = true;
      this.apiService.createOrder(this.main.cart).subscribe(
        data => {
          this.finished = true;
          this.clickedConfirm = false;
          this.main.cart = {products: []};
          localStorage.setItem('cart', JSON.stringify(this.main.cart));

        }, err => {
          localStorage.setItem('autoLogin', this.router.url);
          if(err.status == 470){
            console.log(err);
            this.snackBar.open(err.error.message, "OK", {duration: 3000});

          }else{
            this.main.autoLogin(err.status);
          }

        }
      )
    }
  }

  ok() {
    this.router.navigate(['main/products']);
    this.finished = false;
  }


}
