import { Component, OnInit } from '@angular/core';
import {NewsOrNotice, Supplier} from '../model';
import {ActivatedRoute, Router} from '@angular/router';
import {MainComponent} from '../main/main.component';
import {ApiServiceService} from '../api-service.service';

@Component({
  selector: 'app-specific-list',
  templateUrl: './specific-list.component.html',
  styleUrls: ['./specific-list.component.css']
})
export class SpecificListComponent implements OnInit {

  id: number;
  type: number;
  page = 0;
  objects: NewsOrNotice[];
  suppliers: Supplier[];
  loading = true;

  constructor(private route: ActivatedRoute, private main: MainComponent, private apiService: ApiServiceService, private router: Router) { }

  ngOnInit() {
    this.page = 0;
    this.route.params.subscribe(params => {
      this.type = +params['type'];

      if (this.type === 1) {
        this.main.text = 'news';
        this.apiService.getNews(this.page).subscribe(
          (data: NewsOrNotice[]) => {
            this.objects = data;
            this.loading = false;
          },
          err => {
            localStorage.setItem('autoLogin', this.router.url);
            this.main.autoLogin(err.status);
          }
        );
      } else if (this.type === 2) {
        this.main.text = 'notifications';
        this.apiService.getNotices(this.page).subscribe(
          (data: NewsOrNotice[]) => {
            this.objects = data;
            this.loading = false;
          },
          err => {
            localStorage.setItem('autoLogin', this.router.url);
            this.main.autoLogin(err.status);
          }
        );
      } else if (this.type === 3) {
        this.main.text = 'suppliers';
        const filter = {active : 1};
        this.apiService.filterSuppliers(filter, this.page).subscribe(
          (suppliers: Supplier[]) => {
            this.suppliers = suppliers;
            this.loading = false;
          },
          err => {
            localStorage.setItem('autoLogin', this.router.url);
            this.main.autoLogin(err.status);

          }
        );
      }
    });
  }

  onNavClick(address: string, id, type) {
    if (address === 'main/list') {
      this.page = 0;
      this.type = +type;
      this.loading = true;
      this.objects = [];
      this.suppliers = [];


    }
      if (id != -1) {
        this.router.navigate([address, id, type]);
      } else {
        this.router.navigate([address, type]);
      }



  }

  onScroll() {
    this.page++;
    if (this.type === 1) {
      this.main.text = 'news';
      this.apiService.getNews(this.page).subscribe(
        (data: NewsOrNotice[]) => {
          this.objects = [...this.objects, ...data];
          this.loading = false;
        },
        err => {
          localStorage.setItem('autoLogin', this.router.url);
          this.main.autoLogin(err.status);
        }
      );
    } else if (this.type === 2) {
      this.main.text = 'notifications';
      this.apiService.getNotices(this.page).subscribe(
        (data: NewsOrNotice[]) => {
          this.objects = [...this.objects, ...data];
          this.loading = false;
        },
        err => {
          localStorage.setItem('autoLogin', this.router.url);
          this.main.autoLogin(err.status);
        }
      );
    } else if (this.type === 3) {
      this.main.text = 'suppliers';
      const filter = {active : 1};
      this.apiService.filterSuppliers(filter, this.page).subscribe(
        (suppliers: Supplier[]) => {
          this.suppliers = [...this.suppliers, ...suppliers];
          this.loading = false;
        },
        err => {
          localStorage.setItem('autoLogin', this.router.url);
          this.main.autoLogin(err.status);

        }
      );
    }
  }

}
