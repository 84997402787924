import {Component, OnInit} from '@angular/core';
import {Manufacturer, Product, ProductFilter, Supplier} from "../model";
import {Router} from "@angular/router";
import {StartComponent} from "../start/start.component";
import {DataService} from "../data.service";
import {ApiServiceService} from "../api-service.service";
import {MainComponent} from "../main/main.component";
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material';

@Component({
  selector: 'app-favorite-products',
  templateUrl: './favorite-products.component.html',
  styleUrls: ['./favorite-products.component.css']
})
export class FavoriteProductsComponent implements OnInit {

  page = 0;
  timer;
  products: Product[];
  loading: boolean;
  searchText: string;
  showPlaceholder = true;
  srcIcon = '/assets/gear.png';
  manufacturers: Manufacturer[];
  suppliers: Supplier[];
  manufacturerPage = 0;
  supplierPage = 0;
  allManufactures = false;
  allSuppliers = false;
  title = 'products';
  predictions = [];
  rest = 0;

  public productFilter: ProductFilter = {active: 1, action: 0, stock: -1, manufacturers: [0], suppliers: [0], name: ''};

  constructor(private snackBar: MatSnackBar, private translate: TranslateService, private router: Router, public start: StartComponent, private dataService: DataService, private apiService: ApiServiceService, public main: MainComponent) { }

  ngOnInit() {
    // if (localStorage.getItem('searchText')) {
    //   this.start.searchText = localStorage.getItem('searchText');
    //   if (this.start.searchText != '') {
    //     this.showPlaceholder = false;
    //     this.productFilter.name = this.start.searchText;
    //   }
    // }
    this.start.searchText = '';
    if (localStorage.getItem('productsTitle')) {
      this.title = localStorage.getItem('productsTitle');
      localStorage.removeItem('productsTitle');
    }

    if (localStorage.getItem('productFilterFavorite')) {
      this.productFilter = JSON.parse(localStorage.getItem('productFilterFavorite'));
      this.filterProducts();
    } else {
      this.productFilter = {active: 1, favorite:1, action: 0, stock: -1, manufacturers: [0], suppliers: [0], name: this.start.searchText};
      this.getProducts();

    }

    this.getManufacturers();
    this.getSuppliers();

    if (window.innerWidth > 1400) {
      this.srcIcon = '/assets/search_top.png';
    } else if (window.innerWidth <= 1400) {
      this.srcIcon = '/assets/gear.png';
    }
  }

  openProductDetails(id) {
    this.router.navigate(['main/product', id]);
  }

  ifMobileFilter() {
    if (window.innerWidth <= 1400) {
      this.router.navigate(['main/favorite/filter']);
    }
  }

  adjustPrice(index) {
    let price = 0;

    const product = this.products[index];

    //check if rabat exists

    if (product.rabat) {
      if (product.rabat.newPrice && !product.rabat.conditionAmount) { //first case, price automatically changed
        price = product.rabat.newPrice;
        product.appliedRabat = true;
      } else if (product.rabat.percent && !product.rabat.conditionAmount) { //second case, no condition
        product.appliedRabat = true;
        price = product.fullPrice - (product.fullPrice / 100 * product.rabat.percent);
      } else if (product.chosenAmount >= product.rabat.conditionAmount) { // condition met
        product.appliedRabat = true;
        if (product.rabat.percent) {
          price = product.fullPrice - (product.fullPrice / 100 * product.rabat.percent);
        } else {
          price = product.rabat.newPrice;
        }
      } else if (product.action && product.action > 0) { // if rabat condition not met, check if there's action
        product.appliedRabat = true;
        price = product.fullPrice - (product.fullPrice / 100 * product.action);
      } else {
        product.appliedRabat = true;
        price = product.fullPrice;
      }

      this.products[index].price = price;
      this.products = [...this.products];

    } else if (product.action && product.action > 0) { // if rabat doesn't exist, check if there's action
      price = product.fullPrice - (product.fullPrice / 100 * product.action);

      this.products[index].price = price;
      this.products = [...this.products];
    }



  }


  searchProducts() {
    this.productFilter.name = this.start.searchText;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.loading = true;
      this.apiService.filterProducts(this.productFilter, 0). subscribe(
        (products: Product[]) => {
          this.products = [...products];
          this.loading = false;
        }, err => {
          localStorage.setItem('autoLogin', this.router.url);
          this.main.autoLogin(err.status);
        }
      );
      if ( this.start.searchText != '')
      this.apiService.getProductPrediction(this.start.searchText).subscribe(
        (data: String[]) => {
          this.predictions = data;
        }, err => {
          localStorage.setItem('autoLogin', this.router.url);
          this.main.autoLogin(err.status);
        }
      );

    }, 500);
  }

  checkIfSelected(id, type) {


    if (type == 'm') { //manufacturers

      const index = this.productFilter.manufacturers.findIndex(x => x == id);

      if (index == -1) {
        return false;
      } else {
        return true;
      }

    } else if (type == 's') {//suppliers

      const index = this.productFilter.suppliers.findIndex(x => x == id);

      if (index == -1) {
        return false;
      } else {
        return true;
      }
    }

  }

  toggleCheck(id, type) {
    if (type == 'm') { //manufacturers

      const index = this.productFilter.manufacturers.findIndex(x => x == id);

      if (index == -1) {
        if (id == 0) {
          this.productFilter.manufacturers = [0];
        }
        this.productFilter.manufacturers.push(id);


      } else {
        this.productFilter.manufacturers.splice(index, 1);
      }

      if (this.productFilter.manufacturers.length > 0) { // if any other option checked remove 'all'
        const zeroIndex = this.productFilter.manufacturers.findIndex(x => x == 0);
        if (zeroIndex != -1) {
          this.productFilter.manufacturers.splice(zeroIndex, 1);
        }

      }

      if (this.productFilter.manufacturers.length == 0) {
        this.productFilter.manufacturers = [0];
      }

      this.productFilter.manufacturers = [...this.productFilter.manufacturers];


    } else if (type == 's') {//suppliers

      const index = this.productFilter.suppliers.findIndex(x => x == id);

      if (index == -1) {
        if (id == 0) {
          this.productFilter.suppliers = [0];
        }
        this.productFilter.suppliers.push(id);
      } else {
        this.productFilter.suppliers.splice(index, 1);
      }

      if (this.productFilter.suppliers.length > 0) { // if any other option checked remove 'all'
        const zeroIndex = this.productFilter.suppliers.findIndex(x => x == 0);
        if (zeroIndex != -1) {
          this.productFilter.suppliers.splice(zeroIndex, 1);
        }

      }

      if (this.productFilter.suppliers.length == 0) {
        this.productFilter.suppliers = [0];
      }

      this.productFilter.suppliers = [...this.productFilter.suppliers];
    }

    localStorage.setItem('productFilterFavorite', JSON.stringify(this.productFilter));
    this.filterProducts();
  }

  filterProducts(){
    this.productFilter.name = this.start.searchText;
    this.loading = true;
    this.apiService.filterProducts(this.productFilter, 0).subscribe(
      (products: Product[]) => {
        this.products = [...products];
        this.loading = false;
      },
      err => {
        this.loading = false;
        localStorage.setItem('autoLogin', this.router.url);
        this.main.autoLogin(err.status);
      }
    )
  }

  closePrediction() {
    this.predictions = [...[]];
  }

  chosePrediction(name) {
    this.start.searchText = name;
    this.productFilter.name = name;
    this.predictions = [];
    this.loading = true;
    this.apiService.filterProducts(this.productFilter, 0). subscribe(
      (products: Product[]) => {
        this.products = [...products];
        this.loading = false;
      }, err => {
        localStorage.setItem('autoLogin', this.router.url);
        this.main.autoLogin(err.status);
      }
    );
  }

  getManufacturers() {
    this.apiService.getManufacturers(this.manufacturerPage).subscribe(
      (manufacturers: Manufacturer[] ) => {
        this.manufacturers = manufacturers.slice(0,4);
      },
      err => {
        localStorage.setItem('autoLogin', this.router.url);
        this.main.autoLogin(err.status);
      }
    )
  }

  getSuppliers() {
    this.apiService.getSuppliers(this.supplierPage).subscribe(
      (suppliers: Supplier[] ) => {
        this.suppliers = suppliers.slice(0, 4);
      },
      err => {
        localStorage.setItem('autoLogin', this.router.url);
        this.main.autoLogin(err.status);
      }
    )
  }

  onResize(event) {
    if (window.innerWidth > 1400) {
      this.srcIcon = '/assets/search_top.png';
    } else {
      this.srcIcon = '/assets/gear.png';
    }
  }

  chosenAmountFromCart(id) {
    const index = this.main.cart.products.findIndex(x => x.id === id);

    if (index == -1) {
      return 0;
    } else {
      return +this.main.cart.products[index].chosenAmount;
    }
  }

  getProducts() {
    this.loading = true;
    const filter = { active : 1, stock: -1, favorite: 1};
    this.apiService.filterProducts(filter, this.page).subscribe(
      (products: Product[]) => {
        this.products = products;
        this.loading = false;
      },
      err => {
        localStorage.setItem('autoLogin', this.router.url);
        this.loading = false;
        this.main.autoLogin(err.status);
      }
    );
  }

  getTitle(p) {
    if (p.isBlocked) {
      return this.translate.instant('productBlocked');
    } else if (p.inStock == 0) {
      return this.translate.instant('outOfStock');
    } else {
      return p.name;
    }
  }

  getTitleSmall(p) {
    if (p.isBlocked) {
      return this.translate.instant('productBlocked');
    } else if (p.inStock == 0) {
      return this.translate.instant('outOfStock');
    } else {
      return false;
    }
  }


  onFocus() {
    this.showPlaceholder = false;
  }

  onBlur() {
    if (this.start.searchText === '') {
      this.showPlaceholder = true;
    }
  }

  expandProduct(id) {
    const index = this.products.findIndex(x => x.id === id);
    if (this.products[index].expanded === true) {
      this.products[index].expanded = false;
    } else {
      this.products[index].expanded = true;
    }

    this.products = [...this.products];
  }


  findInCart(id) {

    const index = this.main.cart.products.findIndex(x => x.id === id);

    if (index == -1) {
      return false;
    } else {
      return true;
    }
  }

  toggleChosenPacket(id) {
    const index = this.products.findIndex(x => x.id === id);

    if (this.products[index].isBlocked || this.products[index].inStock == 0) {
      return;
    }


    if (this.products[index].chosenPacket === true) {
      this.products[index].chosenPacket = false;
    } else {
      this.products[index].chosenPacket = true;
    }

    this.products = [...this.products];
  }

  addToCart(id) {


    const index = this.products.findIndex(x => x.id === id);

    if (this.products[index].chosenAmount != undefined) {
      if (this.products[index].chosenAmount > 0) {
        if (this.products[index].chosenAmount > 0 && !this.products[index].isBlocked || this.products[index].inStock != 0) {
          this.main.cart.products.push(this.products[index]);

          localStorage.setItem('cart', JSON.stringify(this.main.cart));
        }

      }
    }
  }

  addAmount(product){

    if (this.findInCart(product.id)) {
      return;
    }

    const index = this.products.findIndex(x => x.id === product.id);

    if (this.products[index].isBlocked || this.products[index].inStock == 0) {
      return;
    }

    if (!this.products[index].chosenAmount) {
      this.products[index].chosenAmount = 0;
    }
    if(product.lager > this.products[index].chosenAmount){
      if (this.products[index].chosenPacket) {

        this.products[index].chosenAmount = this.products[index].chosenAmount + (1 * this.products[index].packetNumber);
      } else {
        this.products[index].chosenAmount = this.products[index].chosenAmount + 1;
      }
      this.rest = this.products[index].chosenAmount;

      this.adjustPrice(index);
      this.products = [...this.products];

    }else{
      this.snackBar.open(this.translate.instant('Nema dovoljno na proizvoda na lageru'), "OK", {duration : 3000});
    }
  }
  public lagerSnack(product){
    const lagerNumber = product.lager - this.rest;
    this.snackBar.open(this.translate.instant('Na lageru je preostalo ' + lagerNumber + ' proizvoda'), "OK", {duration : 3000});

  }

  removeAmount(id) {

    if (this.findInCart(id)) {
      return;
    }

    const index = this.products.findIndex(x => x.id === id);

    if (this.products[index].isBlocked || this.products[index].inStock == 0) {
      return;
    }
    if (!this.products[index].chosenAmount) {
      this.products[index].chosenAmount = 0;
    }






    if (this.products[index].chosenPacket){
      this.products[index].chosenAmount = this.products[index].chosenAmount - (1 * this.products[index].packetNumber);
    } else {
      this.products[index].chosenAmount = this.products[index].chosenAmount - 1;
    }

    if(this.products[index].chosenAmount < 0 ) {
      this.products[index].chosenAmount = 0;
    }
    this.rest = this.products[index].chosenAmount ;
    this.adjustPrice(index);
    this.products = [...this.products];
  }

  onScroll() {
    this.page++;
    this.apiService.filterProducts(this.productFilter, this.page).subscribe(
      (products: Product[]) => {
        this.products = [...this.products, ...products];
      },
      err => {
        localStorage.setItem('autoLogin', this.router.url);
        this.main.autoLogin(err.status);
      }
    );

  }

  showAll(type) {
    if (type == 'm') {
      this.apiService.getAllManufacturers().subscribe(
        (manufacturers: Manufacturer[]) => {
          this.manufacturers = [...manufacturers];
          this.allManufactures = true;
        },
        err => {
          localStorage.setItem('autoLogin', this.router.url);
          this.main.autoLogin(err.status);
        }
      )
    } else if (type == 's'){
      this.apiService.getAllSuppliers().subscribe(
        (suppliers: Supplier[]) => {
          this.suppliers = [...suppliers];
          this.allSuppliers = true;
        },
        err => {
          localStorage.setItem('autoLogin', this.router.url);
          this.main.autoLogin(err.status);
        }
      )
    }
  }

  getProductTitle(product) {
    if (product.appliedRabat == true) {
      return 'appliedRabat';
    } else {
      return 'action';
    }
  }

  changedFromField(id) {
    const index = this.products.findIndex((x) => x.id == id);

    if (index != -1) {
      if (this.products[index].chosenAmount == null || !this.products[index].chosenAmount || this.products[index].chosenAmount <= 0) {

        if (this.products[index].chosenAmount < 0) {
          this.products[index].chosenAmount = this.products[index].chosenAmount * -1;
        } else {
          this.products[index].chosenAmount = 0;
          this.products = [...this.products];
        }
      }
    }
  }

}
