import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {Customer} from '../model';
import {ApiServiceService} from '../api-service.service';
import {MatSnackBar} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  constructor(private router: Router, private apiService: ApiServiceService, private snackBar: MatSnackBar, private translate: TranslateService) { }

  customer: Customer = {legalName: '', identifier: '', objectName: '', address: '', objectPhone: '', purchasePhone: '', email: '', username: '', password: '', confirmPassword: ''};

  onNavClick(address: string) {
    this.router.navigate([address]);
  }

  ngOnInit() {
    if (localStorage.getItem('customer') != null) {
      this.router.navigate(['main/start']);
    }
    this.apiService.getLocale().subscribe(
      (data) => {
        this.customer.locale = data['locale'];
      },
      err => {
        this.customer.locale = 'sr';
      }
    );
  }

  emailIsValid () {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.customer.email)
  }

  register() {
    if (!this.emailIsValid()) {
      this.snackBar.open(this.translate.instant("emailNotValid"), "OK", {duration: 3000});
      return;
    } else if (this.customer.legalName === '' || this.customer.identifier === '' || this.customer.objectName === '' || this.customer.address === '' || this.customer.objectPhone === ''
    || this.customer.purchasePhone === '' || this.customer.email === '' || this.customer.username === '' || this.customer.password === '' || this.customer.confirmPassword === ''
    ) {
      this.snackBar.open(this.translate.instant('pleaseCompleteAllFieldsUP'), "OK", {duration : 3000});
      return;
    }

    if (this.customer.password !== this.customer.confirmPassword) {
      this.snackBar.open(this.translate.instant('passwordsAreNotSame'), "OK", {duration : 3000});
      return;
    }

    if (this.customer.termsOfUse !== true) {
      this.snackBar.open(this.translate.instant('pleaseAcceptTermsOfUse'), "OK", {duration : 3000});
      return;
    }

    this.apiService.register(this.customer).subscribe(
      (result) => {
        this.customer = result['user'];
        localStorage.setItem('JWT', result['jwt']);


        localStorage.setItem('customer', JSON.stringify(this.customer));
        if (localStorage.getItem('url')) {
          this.router.navigate([localStorage.getItem('url')]);
          localStorage.removeItem('url');
        } else {
          this.router.navigate(['main/start']);
        }
      },
      err => {
        this.snackBar.open(err.error.message, "OK", {duration : 3000});
      }
    );
  }

  public openTermsOfUse() {
    window.open("/terms", '_blank');
  }

}
