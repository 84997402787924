import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Customer} from "../model";
import {Router} from "@angular/router";
import {MainComponent} from "../main/main.component";
import {ApiServiceService} from "../api-service.service";
import {MatSnackBar} from "@angular/material";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit {
customer: Customer;
verification = false;
code = '';
@ViewChild("codeForm", {static: false}) codeForm: ElementRef;
  constructor(private translate: TranslateService, private snackBar: MatSnackBar, private apiService: ApiServiceService, private main: MainComponent, private router: Router) { }

  ngOnInit() {
    this.customer = JSON.parse(localStorage.getItem('customer'));
  }

  changePassword() {
    this.router.navigate(['main/password']);
  }

  requestUpdateProfile() {
    this.apiService.requestUpdate().subscribe(
      data => {
        this.main.text = 'verification';
        this.verification = true;
        setTimeout(() => { //wait 100ms to load this input
          this.codeForm.nativeElement.focus();
        }, 100);
      }, err => {
        localStorage.setItem('autoLogin', this.router.url);
        this.main.autoLogin(err.status);
      }
    )

  }

  confirmCode() {
    this.customer.code = this.code;
    this.apiService.updateProfile(this.customer).subscribe(
      (customer: Customer) => {
        this.customer = customer;
        localStorage.setItem('customer', JSON.stringify(this.customer));
        this.router.navigate(['main/profile']);
      }, err => {
        if (err.status == 404) {
          this.snackBar.open(this.translate.instant('wrongCode'), "OK", {duration : 3000});
        } else {
          localStorage.setItem('autoLogin', this.router.url);
          this.main.autoLogin(err.status);
        }
      }
    )
  }

}
