import {Component, OnInit} from '@angular/core';
import {Cart, Product} from '../model';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiServiceService} from '../api-service.service';
import {MainComponent} from '../main/main.component';
import {MatSnackBar} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.css']
})
export class ProductDetailComponent implements OnInit {
  loading = false;
  product: Product;

  constructor(private translate: TranslateService,private router: Router, public main: MainComponent, private route: ActivatedRoute, private apiService: ApiServiceService, private snackBar: MatSnackBar) {
  }

  ngOnInit() {
    this.loading = true;
    this.route.params.subscribe(params => {
      const id = +params['id'];
      this.apiService.getProduct(id).subscribe(
        (product: Product) => {
          this.product = product;
          this.loading = false;
        }, err => {
          localStorage.setItem('autoLogin', this.router.url);
          this.main.autoLogin(err.status);
        }
      );
    });
  }

  toggleFavorite() {
    this.product.isFavorite = !this.product.isFavorite;
    this.apiService.toggleFavorite(this.product.id).subscribe(
      (product: Product) => {
        this.product.isFavorite = product.isFavorite;
      }, err => {
        localStorage.setItem('autoLogin', this.router.url);
        this.main.autoLogin(err.status);
      }
    );
  }

  toggleChosenPacket() {

    if (this.product.isBlocked || this.product.inStock == 0) {
      return;
    }

    if (this.product.chosenPacket === true) {
      this.product.chosenPacket = false;
    } else {
      this.product.chosenPacket = true;
    }
  }

  adjustPrice() {
    let price = 0;

    const product = this.product;

    //check if rabat exists

    if (product.rabat) {
      if (product.rabat.newPrice && !product.rabat.conditionAmount) { //first case, price automatically changed
        price = product.rabat.newPrice;
      } else if (product.rabat.percent && !product.rabat.conditionAmount) { //second case, no condition
        price = product.fullPrice - (product.fullPrice / 100 * product.rabat.percent);
      } else if (product.chosenAmount >= product.rabat.conditionAmount) { // condition met
        if (product.rabat.percent) {
          price = product.fullPrice - (product.fullPrice / 100 * product.rabat.percent);
        } else {
          price = product.rabat.newPrice;
        }
      } else if (product.action && product.action > 0) { // if rabat condition not met, check if there's action
        price = product.fullPrice - (product.fullPrice / 100 * product.action);
      } else {
        price = product.fullPrice;
      }


      this.product.price = price;

    } else if (product.action && product.action > 0) { // if rabat doesn't exist, check if there's action
      price = product.fullPrice - (product.fullPrice / 100 * product.action);


      this.product.price = price;
    }
  }

  addAmount() {


    if (this.findInCart()) {
      return;
    }

    if (this.product.isBlocked || this.product.inStock == 0) {
      return;
    }

    if (!this.product.chosenAmount) {
      this.product.chosenAmount = 0;
    }
    if(this.product.lager > this.product.chosenAmount ){
    if (this.product.chosenPacket) {

      this.product.chosenAmount = this.product.chosenAmount + (1 * this.product.packetNumber);
    } else {
      this.product.chosenAmount = this.product.chosenAmount + 1;
    }}else{
      this.snackBar.open(this.translate.instant('Nema dovoljno na proizvoda na lageru'), "OK", {duration : 3000});
    }

    this.adjustPrice();
  }


  removeAmount() {

    if (this.findInCart()) {
      return;
    }

    if (this.product.isBlocked || this.product.inStock == 0) {
      return;
    }

    if (!this.product.chosenAmount) {
      this.product.chosenAmount = 0;
    }

    if (this.product.chosenPacket) {
      this.product.chosenAmount = this.product.chosenAmount - (1 * this.product.packetNumber);
    } else {
      this.product.chosenAmount = this.product.chosenAmount - 1;
    }

    if (this.product.chosenAmount < 0) {
      this.product.chosenAmount = 0;
    }

    this.adjustPrice();
  }

  addToCart() {

    if (this.product.chosenAmount != undefined) {
      if (this.product.chosenAmount > 0) {
        if (this.product.chosenAmount > 0 && !this.product.isBlocked || this.product.inStock != 0) {
          this.main.cart.products.push(this.product);

          localStorage.setItem('cart', JSON.stringify(this.main.cart));
        }

      }
    }
  }

  findInCart() {

    const index = this.main.cart.products.findIndex(x => x.id === this.product.id);

    if (index == -1) {
      return false;
    } else {
      return true;
    }
  }

  chosenAmountFromCart() {
    const index = this.main.cart.products.findIndex(x => x.id === this.product.id);

    if (index == -1) {
      return 0;
    } else {
      return +this.main.cart.products[index].chosenAmount;
    }
  }

  getProductTitle(product) {
    if (product.appliedRabat == true) {
      return 'appliedRabat';
    } else {
      return 'action';
    }
  }

  changedFromField() {

    if (this.product.chosenAmount == null || !this.product.chosenAmount || this.product.chosenAmount <= 0) {

      if (this.product.chosenAmount < 0) {
        this.product.chosenAmount = this.product.chosenAmount * -1;
      } else {
        this.product.chosenAmount = 0;
      }
    }

  }


}
