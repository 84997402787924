import { Component, OnInit } from '@angular/core';
import {ApiServiceService} from '../api-service.service';
import {Customer, NewsOrNotice, Product, Supplier} from '../model';
import {MatSnackBar} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {MainComponent} from '../main/main.component';
import {Location} from '@angular/common';
import {ProductsComponent} from "../products/products.component";
import {DataService} from "../data.service";

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.css']
})
export class StartComponent implements OnInit {
public searchText = '';
timer;
showPlaceholder = true;
actionsPage = 0;
suppliersPage = 0;
newsPage = 0;
notificationsPage = 0;
actions: Product[];
suppliers: Supplier[];
news: NewsOrNotice[];
notices: NewsOrNotice[];
loading = {actions: true, suppliers: true, news: true, notices: true};
customer: Customer;

  constructor(private location: Location, private dataService: DataService, private apiService: ApiServiceService, private snackBar: MatSnackBar, private translate: TranslateService, private router: Router, public main: MainComponent) { }

  ngOnInit() {
    this.customer = JSON.parse(localStorage.getItem('customer'));
    this.getActions();
    this.getSuppliers();
    this.getNews();
    this.getNotices();
  }

  openProductDetails(id) {
    this.router.navigate(['main/product', id]);
  }

  searchProducts() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      localStorage.setItem('searchText', this.searchText);
      this.router.navigate(['main/products']);
    }, 500);
  }

  openActions() {

    const filter = {active: 1, action: 1, stock: -1, manufacturers: [0], suppliers: [0], name: ''};
    localStorage.setItem('productFilter', JSON.stringify(filter));
    localStorage.setItem('productsTitle', 'actions');
    this.router.navigate(['main/products']);

  }

  openFavorites() {
    this.main.text = 'favorites';
    this.router.navigate(['main/favorite']);

  }

  resetAndOpenProducts() {
    const filter = {active: 1, action: 0, favorite: 0, stock: -1, manufacturers: [0], suppliers: [0], name: ''};
    localStorage.setItem('productFilter', JSON.stringify(filter));
    localStorage.setItem('productsTitle', 'products');
    this.router.navigate(['main/products']);
  }

  onNavClick(address: string, id, type) {
    if (id == -1 && type == -1) {
      this.router.navigate([address]);
    } else if (id == -1) {
      this.router.navigate([address, type]);
    } else {
      this.router.navigate([address, id, type]);
    }

  }

  onFocus() {
    this.showPlaceholder = false;
  }

  onBlur() {
    if (this.searchText === '') {
      this.showPlaceholder = true;
    }
  }

  getActions() {
    const filter = {active : 1, action : 1, stock: -1};
    this.apiService.filterProducts(filter, this.actionsPage).subscribe(
      (products: Product[]) => {
        this.actions = products;
        this.loading.actions = false;
      },
      err => {
      localStorage.setItem('autoLogin', this.router.url);
      this.main.autoLogin(err.status);
      }
    );
  }

  getSuppliers() {
    const filter = {active : 1};
    this.apiService.filterSuppliers(filter, this.suppliersPage).subscribe(
      (suppliers: Supplier[]) => {
        this.suppliers = suppliers;
        this.loading.suppliers = false;
      },
      err => {
        localStorage.setItem('autoLogin', this.router.url);
        this.main.autoLogin(err.status);

      }
    );
  }

  getNews() {
    this.apiService.getNews(this.newsPage).subscribe(
      (news: NewsOrNotice[]) => {
          this.news = news;
          this.loading.news = false;
      },
      err => {
        localStorage.setItem('autoLogin', this.router.url);
        this.main.autoLogin(err.status);

      }
    );
  }

  logOut() {
    localStorage.removeItem('customer');
    localStorage.removeItem('cart');
    localStorage.removeItem('searchText');
    this.router.navigate(['login']);
  }

  getNotices() {
    this.apiService.getNotices(this.notificationsPage).subscribe(
      (notices: NewsOrNotice[]) => {
        this.notices = notices;
        this.loading.notices = false;

      },
      err => {
        localStorage.setItem('autoLogin', this.router.url);
        this.main.autoLogin(err.status);

      }
    );
  }



}
