import {Component, HostListener, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {Manufacturer, ProductFilter, Supplier} from "../model";
import {ApiServiceService} from "../api-service.service";
import {MainComponent} from "../main/main.component";
import {ProductsComponent} from "../products/products.component";

@Component({
  selector: 'app-filter-products',
  templateUrl: './filter-products.component.html',
  styleUrls: ['./filter-products.component.css']
})

@HostListener('window:resize', ['$event'])
export class FilterProductsComponent implements OnInit {

  public productFilter: ProductFilter = {active: 1, stock: -1, manufacturers: [0], suppliers: [0], name: '', action: 0};
  manufacturers: Manufacturer[];
  suppliers: Supplier[];
  title = '';

  constructor(private apiService: ApiServiceService, private main: MainComponent, private router: Router) { }

  ngOnInit() {
    this.getManufacturers();
    this.getSuppliers();

    if (localStorage.getItem('productsTitleFilter')) {
      this.title = localStorage.getItem('productsTitleFilter');
      localStorage.removeItem('productsTitleFilter');
    }

    if (window.innerWidth > 1400) {
      this.router.navigate(['main/products']);
    }
  }

  onResize(event) {
    if (window.innerWidth > 1400) {
      this.router.navigate(['main/products']);
    }
  }

  getManufacturers() {
    this.apiService.getAllManufacturers().subscribe(
      (manufacturers: Manufacturer[]) => {
        this.manufacturers = manufacturers;
      },
      err => {
        localStorage.setItem('autoLogin', this.router.url);
        this.main.autoLogin(err.status);
      }
    )
  }

  getSuppliers() {
    this.apiService.getAllSuppliers().subscribe(
      (suppliers: Supplier[]) => {
        this.suppliers = suppliers;
      },
      err => {
        localStorage.setItem('autoLogin', this.router.url);
        this.main.autoLogin(err.status);
      }
    )
  }

  toggleCheck(id, type) {

    if (type == 'm') { //manufacturers

      if (id == 0) {
        this.productFilter.manufacturers = [0];
      }

      if (this.productFilter.manufacturers.length > 0) { // if any other option checked remove 'all'
        const zeroIndex = this.productFilter.manufacturers.findIndex(x => x == 0);
        if (zeroIndex != -1) {
          this.productFilter.manufacturers.splice(zeroIndex, 1);
        }

      }

      if (this.productFilter.manufacturers.length == 0) {
        this.productFilter.manufacturers = [0];
      }

      this.productFilter.manufacturers = [...this.productFilter.manufacturers];


    } else if (type == 's') {//suppliers

      if (id == 0) {
        this.productFilter.suppliers = [0];
      }

      if (this.productFilter.suppliers.length > 0) { // if any other option checked remove 'all'
        const zeroIndex = this.productFilter.suppliers.findIndex(x => x == 0);
        if (zeroIndex != -1) {
          this.productFilter.suppliers.splice(zeroIndex, 1);
        }

      }

      if (this.productFilter.suppliers.length == 0) {
        this.productFilter.suppliers = [0];
      }

      this.productFilter.suppliers = [...this.productFilter.suppliers];
    }
  }

  reset() {
    this.productFilter = {active: 1, action: 0, stock: -1, manufacturers: [0], suppliers: [0], name: ''};
  }

  apply() {
    localStorage.setItem('productFilter', JSON.stringify(this.productFilter));
    this.router.navigate(['main/products']);
  }

  toggleAction() {
    if (this.productFilter.action == 1) {
      this.productFilter.action = 0;
    } else {
      this.productFilter.action = 1;
    }
  }

}
