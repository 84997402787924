import { Component, OnInit } from '@angular/core';
import {ApiServiceService} from "../api-service.service";
import {MainComponent} from "../main/main.component";
import {NewsOrNotice, Supplier} from "../model";
import {Router} from "@angular/router";

@Component({
  selector: 'app-suppliers',
  templateUrl: './suppliers.component.html',
  styleUrls: ['./suppliers.component.css']
})
export class SuppliersComponent implements OnInit {
loading = false;
page = 0;
suppliers: Supplier[];
showPlaceholder = true;
searchText = '';
search = false;
timer;
  constructor(private router: Router, private apiService: ApiServiceService, private main: MainComponent) { }

  ngOnInit() {
    this.getSuppliers();
  }

  searchSuppliers(){
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.loading = true;
      const filter = {name: this.searchText, active: 1};
      this.search = true;
      this.page = 0;
      this.apiService.filterSuppliers(filter, this.page).subscribe(
        (suppliers: Supplier[]) => {
          this.suppliers = [...suppliers];
          this.loading = false;
        }, err => {
          localStorage.setItem('autoLogin', this.router.url);
          this.main.autoLogin(err.status);
        }
      );
    }, 500);
  }

  onFocus() {
    this.showPlaceholder = false;
  }

  openSupplierDetails(id) {
    this.router.navigate(['main/specifics', id, '3']);
  }

  getSuppliers() {
    this.loading = true;
    this.apiService.getSuppliers(0).subscribe(
      (suppliers: Supplier[]) => {
        this.suppliers = suppliers;
        this.loading = false;
      },
      err => {
        localStorage.setItem('autoLogin', this.router.url);
        this.main.autoLogin(err.status);
      }
    );
  }

  onScroll() {
    this.page = this.page + 1;

    if (!this.search) {
      this.apiService.getSuppliers(this.page).subscribe(
        (suppliers: Supplier[]) => {
          this.suppliers = [...this.suppliers, ...suppliers];
        },
        err => {
          localStorage.setItem('autoLogin', this.router.url);
          this.main.autoLogin(err.status);
        }
      );
    } else {
      const filter = {name: this.searchText, active: 1};
      this.apiService.filterSuppliers(filter, this.page).subscribe(
        (suppliers: Supplier[]) => {
          this.suppliers = [...this.suppliers, ...suppliers];
        },
        err => {
          localStorage.setItem('autoLogin', this.router.url);
          this.main.autoLogin(err.status);
        }
      );
    }

  }

  onBlur() {
    if (this.searchText === '') {
      this.showPlaceholder = true;
    }
  }

}
