import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiServiceService {

  constructor(private http: HttpClient) { }
  BASE_API_URL = environment.url;
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  options = { headers: this.headers };

  private authToken() {
    // create authorization header with jwt token
    const jwt = localStorage.getItem('JWT');

    if (jwt) {
      const headers = new HttpHeaders({
        Authorization: jwt,
        'Content-Type': 'application/json',
      });
      return {headers};
    }
  }

  checkUsername(username) {
    return this.http.get(this.BASE_API_URL + '/customer/username/check/' + username, this.options);
  }

  login(user) {
    return this.http.post(this.BASE_API_URL + '/customer/login',  JSON.stringify(user), this.options);
  }

  addComment(comment) {
    return this.http.post(this.BASE_API_URL +  "/comment/add",  JSON.stringify(comment), this.options);
  }

  loginWithToken(user) {
    return this.http.post(this.BASE_API_URL + '/customer/remember/login',  JSON.stringify(user), this.options);
  }

  register(user) {
    return this.http.post(this.BASE_API_URL + '/customer/register',  JSON.stringify(user), this.options);
  }

  forgottenPassword(email) {
    return this.http.get(this.BASE_API_URL +  "/customer/pass/forgot/" + email, this.options);
  }

  requestUpdate() {
    return this.http.get(this.BASE_API_URL + "/customer/update/request", this.authToken());
  }

  getProductPrediction(name) {
    return this.http.get(this.BASE_API_URL + "/customer/manufacturer/prediction/" + name, this.authToken());
  }

  updateProfile(customer) {
    return this.http.post(this.BASE_API_URL + '/customer/update',  JSON.stringify(customer), this.authToken());
  }

  changePassword(code, password, oldPassword) {
    const customer = {code, password, oldPassword};
    return this.http.post(this.BASE_API_URL + "/customer/password/change",  JSON.stringify(customer), this.authToken());
  }



  addToCart(cart) {
    return this.http.post(this.BASE_API_URL + '/customer/cart/add',  JSON.stringify(cart), this.authToken());
  }

  createOrder(cart) {
    return this.http.post(this.BASE_API_URL + '/customer/order/create',  JSON.stringify(cart), this.authToken());
  }

  filterProducts(filter, page) {
    return this.http.post(this.BASE_API_URL + '/customer/products/filter/' + page,  JSON.stringify(filter), this.authToken());
  }

  getOrders(filter, page) {
    return this.http.post(this.BASE_API_URL + '/customer/orders/all/' + page,  JSON.stringify(filter), this.authToken());
  }

  toggleFavorite(id) {
    return this.http.get(this.BASE_API_URL + "/product/favorite/" + id, this.authToken());
  }

  getOrder(id) {
    return this.http.get(this.BASE_API_URL + "/customer/order/get/" + id, this.authToken());
  }

  getProduct(id) {
    return this.http.get(this.BASE_API_URL + "/customer/product/get/" + id, this.authToken());
  }

  sendInvoice(id) {
    return this.http.get(this.BASE_API_URL + "/customer/order/invoice/send/" + id, this.authToken());
  }



  deleteOrder(id) {
    return this.http.get(this.BASE_API_URL + "/customer/order/hide/" + id, this.authToken());
  }

  getManufacturers(page) {
    return this.http.get(this.BASE_API_URL + '/customer/manufacturer/get/' + page,  this.authToken());
  }

  getAllManufacturers() {
    return this.http.get(this.BASE_API_URL + '/customer/manufacturers/all',  this.authToken());
  }

  getAllSuppliers() {
    return this.http.get(this.BASE_API_URL + '/customer/suppliers/all',  this.authToken());
  }

  getSuppliers(page) {
    const filter = {active: 1};
    return this.http.post(this.BASE_API_URL + '/customer/suppliers/get/' + page, JSON.stringify(filter),  this.authToken());
  }

  filterSuppliers(filter, page) {
    return this.http.post(this.BASE_API_URL + '/customer/suppliers/get/' + page,  JSON.stringify(filter), this.authToken());
  }

  getNews(page) {
    return this.http.get(this.BASE_API_URL + '/customer/news/all/' + page,  this.authToken());
  }

  getNewsOrNotice(id, type) {
    return this.http.get(this.BASE_API_URL + '/newsOrNotice/get/' + id + '/' + type,  this.authToken());
  }



  getNotices(page) {
    return this.http.get(this.BASE_API_URL + '/customer/notices/all/' + page,  this.authToken());
  }


  getLocale() {
    return this.http.get(this.BASE_API_URL + '/locale/get', this.options);
  }

  requestRememberToken() {
    return this.http.get(this.BASE_API_URL + '/customer/remember/request', this.authToken());
  }
}
