import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {MainComponent} from './main/main.component';
import {ContactPageComponent} from './contact-page/contact-page.component';
import {RegisterComponent} from './register/register.component';
import {StartComponent} from './start/start.component';
import {NewsNoticeComponent} from './news-notice/news-notice.component';
import {SpecificListComponent} from './specific-list/specific-list.component';
import {ProductsComponent} from './products/products.component';
import {FilterProductsComponent} from './filter-products/filter-products.component';
import {CartComponent} from './cart/cart.component';
import {CheckOrderComponent} from './check-order/check-order.component';
import {OrderHistoryComponent} from './order-history/order-history.component';
import {FilterOrderComponent} from './filter-order/filter-order.component';
import {FavoriteProductsComponent} from './favorite-products/favorite-products.component';
import {FavoriteProductsFilterComponent} from './favorite-products-filter/favorite-products-filter.component';
import {OrderComponent} from './order/order.component';
import {ProductDetailComponent} from './product-detail/product-detail.component';
import {ProfileComponent} from './profile/profile.component';
import {EditPasswordComponent} from './edit-password/edit-password.component';
import {EditProfileComponent} from './edit-profile/edit-profile.component';
import {ForgottenPassComponent} from './forgotten-pass/forgotten-pass.component';
import {SuppliersComponent} from './suppliers/suppliers.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {HelpComponent} from './help/help.component';
import {FaqComponent} from './faq/faq.component';
import {TermsOfUseComponent} from './terms-of-use/terms-of-use.component';
import {PrivacyPolicyComponent} from './privacy-policy/privacy-policy.component';


const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: LoginComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'contact',
    component: ContactPageComponent
  },

  {
    path: 'help',
    component: HelpComponent
  },

  {
    path: 'password',
    component: ForgottenPassComponent
  },
  {
    path: 'terms',
    component: TermsOfUseComponent
  },
  {
    path: 'main',
    component: MainComponent,
    children: [
      {
        path: 'start',
        component: StartComponent
      },
      {
        path: 'faq',
        component: FaqComponent
      },
      {
        path: 'terms',
        component: TermsOfUseComponent
      },
      {
        path: 'privacy',
        component: PrivacyPolicyComponent
      },
      {
        path: 'products',
        component: ProductsComponent
      },
      {
        path: 'product/:id',
        component: ProductDetailComponent
      },
      {
        path: 'specifics/:id/:type',
        component: NewsNoticeComponent
      },
      {
        path: 'filter',
        component: FilterProductsComponent
      },
      {
        path: 'cart',
        component: CartComponent
      },
      {
        path: 'orders',
        component: OrderHistoryComponent,
      },
      {
        path: 'order/:id',
        component: OrderComponent,
      },
      {
        path: 'orders/filter',
        component: FilterOrderComponent
      },
      {
        path: 'favorite',
        component: FavoriteProductsComponent
      },
      {
        path: 'favorite/filter',
        component: FavoriteProductsFilterComponent
      },
      {
        path: 'cart/confirm',
        component: CheckOrderComponent
      },
      {
        path: 'list/:type',
        component: SpecificListComponent
      },
      {
        path: 'profile',
        component: ProfileComponent
      },
      {
        path: 'profile/edit',
        component: EditProfileComponent
      },
      {
        path: 'password',
        component: EditPasswordComponent
      },
      {
        path: 'suppliers',
        component: SuppliersComponent
      },
      {
        path: '**',
        component: NotFoundComponent
      }
    ]
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
