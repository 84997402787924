import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {MainComponent} from '../main/main.component';
import {TranslateService} from '@ngx-translate/core';
import {Customer} from '../model';
import {ApiServiceService} from '../api-service.service';
import {MatSnackBar} from '@angular/material';
import {Location} from '@angular/common';
import {MessagingService} from "../shared/messaging.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private location: Location, private messagingService: MessagingService, private router: Router, private parent: MainComponent, private translate: TranslateService, private apiService: ApiServiceService, private snackBar: MatSnackBar) { }

  customer: Customer = {username: '', password: '', rememberMe: false};
  timer;
  typing = false;
  keyboardOpen = false;
  height = 0;

  ngOnInit() {
    this.height = window.innerHeight;
    this.messagingService.requestPermission(123);
    if (localStorage.getItem('customer') != null) {
      this.router.navigate(['main/start']);
    }
    this.apiService.getLocale().subscribe(
      (data) => {
        this.customer.locale = data['locale'];
      },
      err => {
        this.customer.locale = 'sr';
      }
    );
  }

  onNavClick(address: string) {
    this.router.navigate([address]);
  }

  onResize() {

    //if new height is smaller than 70% of origin screen, keyboard open
    const seventyPercent = this.height - ((this.height / 100) * 30);
    if (window.innerHeight < seventyPercent ) {
      this.keyboardOpen = true;
    } else {
      this.keyboardOpen = false;
    }
  }

  openContact() {
    this.router.navigate(['contact']);
    this.parent.backRoute = 'login';
    this.parent.iconType = 0;
    this.parent.text = this.translate.instant('contact');
  }

  searchUsername() {
    //   clearTimeout(this.timer);
    //   this.timer = setTimeout(() => {
    //     this.typing = false;
    //     this.apiService.checkUsername(this.customer.username). subscribe(
    //       data => {
    //
    //       }
    //     );
    //
    //   }, 500);
    }


  login() {
    const rememberMe = this.customer.rememberMe;
    this.apiService.login(this.customer).subscribe(
      (result) => {
        this.customer = result['user'];
        localStorage.setItem('JWT', result['jwt']);
        localStorage.setItem('customer', JSON.stringify(this.customer));

        // if remember me turned on, request and save remember me token
        // tslint:disable-next-line:triple-equals
        if (rememberMe == true) {
          this.apiService.requestRememberToken().subscribe(
            (data) => {
              this.customer.rememberToken = data['rememberToken'];
              this.customer.rememberMe = rememberMe;
              localStorage.setItem('customer', JSON.stringify(this.customer));
            }
          );
        }
        if (localStorage.getItem('url')) {
          this.router.navigate([localStorage.getItem('url')]);
          localStorage.removeItem('url');
        } else {
          this.router.navigate(['main/start']);
        }
      },
      err => {
        this.snackBar.open(err.error.message, "OK", {duration : 3000});
      }
    );
  }

}
