import { Component, OnInit } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {ApiServiceService} from "../api-service.service";
import {MatSnackBar} from "@angular/material";
import {Router} from "@angular/router";
import {Location} from "@angular/common";
import {MainComponent} from "../main/main.component";

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit {
  loggedIn = false;

  constructor(private translate: TranslateService, private apiService: ApiServiceService, private snackBar: MatSnackBar, private router: Router, private location: Location, public main: MainComponent) { }

  ngOnInit() {
    if (localStorage.getItem('customer')) {
      this.loggedIn = true;
    } else {
      this.loggedIn = false;
    }
  }

  onNavClick(address: string, id = -1, type = -1) {
    if (id === -1 && type === -1) {
      this.router.navigate([address]);
    }  else if (id === -1) {
      this.router.navigate([address, type]);
    } else {
      this.router.navigate([address, id, type]);
    }

  }

  logOut() {
    localStorage.removeItem('customer');
    localStorage.removeItem('cart');
    localStorage.removeItem('searchText')
    this.router.navigate(['login']);
  }

  openFavorites() {
    this.main.text = 'favorites';
    this.router.navigate(['main/favorite']);
  }

  openCart() {
    this.main.text = 'cart';
    this.router.navigate(['main/cart']);
  }

  goBack() {
    this.location.back();
  }

}
