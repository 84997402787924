import { Component, OnInit } from '@angular/core';
import {OrderFilter} from "../model";
import {Router} from "@angular/router";

@Component({
  selector: 'app-filter-order',
  templateUrl: './filter-order.component.html',
  styleUrls: ['./filter-order.component.css']
})
export class FilterOrderComponent implements OnInit {

  actualYear = new Date().getFullYear();
  actualMonth = new Date().getMonth();
  months = [{value: 0, name: "january"}, {value: 1, name: "february"}, {value: 2, name: "march"}, {value: 3, name: "april"},
    {value: 4, name: "may"}, {value: 5, name: "june"}, {value: 6, name: "july"}, {value: 7, name: "august"},
    {value: 8, name: "september"}, {value: 9, name: "october"}, {value: 10, name: "november"}, {value: 11, name: "december"}]
  years: number[] = [];
  orderFilter: OrderFilter = {year: this.years, month: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]};


  constructor(private router: Router) { }

  ngOnInit() {
    if (localStorage.getItem('orderFilter')) {
      this.orderFilter = JSON.parse(localStorage.getItem('orderFilter'));
    } else {
      this.orderFilter = {year: this.years, month: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]};
    }
    this.getYears();
  }

  getYears() {
    const year = new Date().getFullYear();

    for (let i = 0; i <= 10; i++) {
      const newYear = year - i;
      this.years.push(newYear);
    }
  }
  reset(){
    this.orderFilter = {year: this.years, month: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]};
  }

  apply() {
    localStorage.setItem('orderFilter', JSON.stringify(this.orderFilter));
    this.router.navigate(['main/orders']);
  }
}
